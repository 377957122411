import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!

  return (_openBlock(), _createBlock(_component_FormField, {
    containerClass: _ctx.containerClass,
    label: _ctx.label,
    name: _ctx.name,
    options: _ctx.countries,
    required: true,
    selectValue: _ctx.value,
    onSelectChange: _ctx.handleChange,
    onSelectCloseOrDeselect: _ctx.handleClose,
    type: "multiselect",
    "show-label": _ctx.showLabel
  }, null, 8, ["containerClass", "label", "name", "options", "selectValue", "onSelectChange", "onSelectCloseOrDeselect", "show-label"]))
}