
import * as bootstrap from 'bootstrap';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import CodeField from '@/components/forms/CodeField.vue';
import { Form } from 'vee-validate';
import FormField from '@/components/forms/FormField.vue';
import {
  Getters as AuthGetters,
  Actions as AuthActions,
} from '@/store/enums/AuthEnums';
import toasts from '@/utils/toasts';

export default defineComponent({
  name: 'link-mobile-modal',
  emits: ['verifyMobile'],
  props: {
    successMessage: {
      type: String,
    },
  },
  data: () => ({
    modal: {} as HTMLElement,
    formData: {} as any,
    form: {} as unknown as typeof Form,
    accountCodes: [] as any,
    formSubmitted: false,
    disable: false,
    loading: false,
    showRetry: false,
  }),
  mounted() {
    // setup the modal reference and event listener
    this.form = this.$refs.verificationCodeForm as typeof Form;
    this.modal = document.getElementById(
      'link_mobile_form_modal'
    ) as HTMLElement;

    if (this.modal) {
      this.modal.addEventListener('shown.bs.modal', (e) => {
        const firstElement = document.getElementsByName(
          'code[1]'
        ) as unknown as typeof FormField;

        this.accountCodes = [];
        this.formSubmitted = false;

        firstElement[0].focus();
      });
    }
  },
  components: {
    Form,
    CodeField,
  },
  computed: {
    ...mapGetters({
      authUser: AuthGetters.GET_AUTH_USER,
      errors: AuthGetters.GET_AUTH_ERROR,
    }),
    linkCounter() {
      return 5.05 * 60 * 1000;
    },
    formSchema() {
      return {};
    },
  },
  methods: {
    ...mapActions({
      sendCode: AuthActions.SEND_MOBILE_CODE,
    }),
    submitForm() {
      if (this.loading) return;
      this.loading = true;

      const params = {
        code: this.accountCodes.join(''),
      };

      this.form.resetForm();
      this.accountCodes = [];
      this.formSubmitted = true;
      this.$emit('verifyMobile', params);
    },
    handleCodeInput(index, value) {
      this.accountCodes[index] = value;
    },
    resetForm() {
      this.form.resetForm();

      // Reset the form values
      this.accountCodes = [];

      (
        bootstrap.Modal.getInstance(
          this.modal as HTMLElement
        ) as bootstrap.Modal
      ).hide();
    },
    onCountdownEnd() {
      this.disable = true;
      this.showRetry = true;
    },
    handleRetryCode() {
      this.disable = false;
      this.showRetry = false;
    },

    doSendCode() {
      const params = {
        mobile_number: this.authUser.mobile_number,
      };

      this.sendCode(params)
        .then(() => {
          this.disable = false;
          this.showRetry = false;
        })
        .catch(() => {
          const { errors, message } = this.errors;
          toasts.error(errors, message);
        });
    },
  },
});
