import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["d-flex flex-row flex-nowrap justify-content-center gap-5 w-80 align-self-center", _ctx.colClass])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(parseInt(_ctx.digits), (index) => {
      return (_openBlock(), _createBlock(_component_Field, {
        key: index,
        name: `code[${index}]`,
        ref_for: true,
        ref: `code_${index}`,
        modelValue: _ctx.codeInput[index],
        "onUpdate:modelValue": ($event: any) => ((_ctx.codeInput[index]) = $event),
        autocomplete: "false",
        disabled: _ctx.disabled,
        placeholder: "",
        type: "number",
        maxlength: 1,
        min: 0,
        max: 9,
        onKeypress: ($event: any) => (_ctx.handleKeypress($event, index)),
        onKeyup: ($event: any) => (_ctx.handleKeyup($event, index)),
        class: "form-control border-primary code-field"
      }, null, 8, ["name", "modelValue", "onUpdate:modelValue", "disabled", "onKeypress", "onKeyup"]))
    }), 128))
  ], 2))
}