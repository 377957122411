import { set, each, has, get, replace, toString, toNumber } from 'lodash';
import moment from 'moment';

export const dateSanitized = (
  payload: {},
  item_names: Array<string>,
  format = 'Y-MM-DD' as string
): {} => {
  each(item_names, (name: string) => {
    if (has(payload, name)) {
      const val = get(payload, name);
      set(payload, name, moment(val).format(format));
    }
  });
  return payload;
};

export const dateFormatter = (date, format = 'DD-MM-YYYY' as string) => {
  return moment(date).format(format);
};

export const numberWithCommas = (x: string | number, comma = true) => {
  if (!x) return 0;
  if (comma) return toString(x).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return toNumber(toString(x).split(',').join(''));
};

// Relace non-numbers characters
export const numberSanitized = (
  payload: {},
  item_names: Array<string>,
  format = /[^0-9]/g as RegExp
): {} => {
  each(item_names, (name: string) => {
    if (has(payload, name)) {
      const val = get(payload, name);
      set(payload, name, replace(toString(val), format, ''));
    }
  });
  return payload;
};

export const imageBuilder = (buff: ArrayBuffer, contentType = 'image/png') => {
  const decode = btoa(
    new Uint8Array(buff).reduce((data, byte) => {
      return data + String.fromCharCode(byte);
    }, '')
  );
  return `data:${contentType};base64,${decode}`;
};
